import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";

import Button from "@coninsa-s2/button";

import SearchModal from "../components/SearchModal";
import LoupeIcon from "../../../home/icons/loupe.inline.svg";

import {
  SEARCH_RENT_PROPERTIES_QUERY,
  SEARCH_RENT_PROPERTIES_COMMERCIAL_QUERY,
  SEARCH_BUY_PROPERTIES_QUERY,
} from "./banner/queries";

import {
  getFacetIndexOptions,
  getFacetBuildingTypes,
  buildSearchQuery,
} from "@coninsa-site/project/src/utils";
import { cn } from "@coninsa-s2/utils";

function SearchTabsB() {
  const [selectedQuery, setSelectedQuery] = useState("rent_properties");
  const [locations, setLocations] = useState([]);
  const [types, setTypes] = useState([]);
  const [locationValue, setLocationValue] = useState(null);
  const [typeValue, setTypeValue] = useState(null);
  const [isButtonDisabled, setButtonDisabled] = useState(true);
  const [toggle, setToggle] = useState(true);
  const [vissible, setVisible] = useState(false);
  const [customSearchText, setCustomSearchText] = useState("");

  const [
    getRentProperties,
    {
      loading: rentLoading,
      error: rentError,
      data: rentData,
      refetch: rentRefetch,
    },
  ] = useLazyQuery(SEARCH_RENT_PROPERTIES_QUERY, { variables: { text: "" } });

  const [
    getRentCommecialProperties,
    {
      loading: rentCommercialLoading,
      error: rentCommercialError,
      data: rentCommercialData,
      refetch: rentCommercialRefetch,
    },
  ] = useLazyQuery(SEARCH_RENT_PROPERTIES_COMMERCIAL_QUERY, {
    variables: { text: "" },
  });

  const [
    getBuyProperties,
    {
      loading: buyLoading,
      error: buyError,
      data: buyData,
      refetch: buyRefetch,
    },
  ] = useLazyQuery(SEARCH_BUY_PROPERTIES_QUERY, { variables: { text: "" } });

  useEffect(() => {
    if (selectedQuery) {
      const runQuery = lazyQueries[selectedQuery];
      runQuery();

      // Reset select options.
      setLocationValue(null);
      setTypeValue(null);
      setButtonDisabled(false);

      setToggle(false);
    }
  }, [selectedQuery]);

  useEffect(() => {
    if (locationValue) {
      const runRefetchQuery = refetchQueries[selectedQuery];
      runRefetchQuery({ text: locationValue?.value || "" });

      // Reset type select
      setTypeValue(null);
    }
  }, [locationValue]);

  useEffect(() => {
    if (buyData?.search?.facets) {
      if (!locationValue) {
        setLocations(getFacetIndexOptions(0, buyData.search.facets));
      }

      setTypes(getFacetBuildingTypes(buyData.search.facets));
    }
  }, [buyData]);

  useEffect(() => {
    if (rentData?.search?.facets) {
      if (!locationValue) {
        setLocations(getFacetIndexOptions(0, rentData.search.facets));
      }

      setTypes(getFacetBuildingTypes(rentData.search.facets));
    }
  }, [rentData]);

  useEffect(() => {
    if (rentCommercialData?.search?.facets) {
      if (!locationValue) {
        setLocations(getFacetIndexOptions(0, rentCommercialData.search.facets));
      }

      setTypes(getFacetBuildingTypes(rentCommercialData.search.facets));
    }
  }, [rentCommercialData]);

  let lazyQueries = {
    rent_properties: getRentProperties,
    rent_properties_commercial: getRentCommecialProperties,
    buy_properties: getBuyProperties,
  };

  let refetchQueries = {
    rent_properties: rentRefetch,
    rent_properties_commercial: rentCommercialRefetch,
    buy_properties: buyRefetch,
  };

  const loading = buyLoading || rentLoading || rentCommercialLoading;

  const handleSubmit = (event) => {
    event.preventDefault();

    const url = buildSearchQuery(
      selectedQuery,
      vissible ? customSearchText : locationValue?.value,
      typeValue?.value
    );

    window.location.replace(url, "_self");
  };

  const toggleSearch = () => {
    setVisible(!vissible);
    setCustomSearchText("");
  };

  // Verifica si estás en el navegador antes de acceder al 'document'
  useEffect(() => {
    if (typeof window !== "undefined") {
      const inputElement = document.getElementById("dynamic-placeholder");

      const placeholders = [
        "Apartamentos en Medellín El Poblado...",
        "Inmuebles en Bogotá, Chapinero 2 alcobas...",
        "Casas en Medellín, Laureles, 4 alcobas...",
      ];

      let placeholderIndex = 0;
      let charIndex = 0;

      function typePlaceholder() {
        const currentPlaceholder = placeholders[placeholderIndex];
        inputElement.setAttribute(
          "placeholder",
          currentPlaceholder.slice(0, charIndex)
        );

        charIndex++;

        if (charIndex > currentPlaceholder.length) {
          charIndex = 0;
          placeholderIndex = (placeholderIndex + 1) % placeholders.length;
          setTimeout(typePlaceholder, 1000);
        } else {
          setTimeout(typePlaceholder, 100);
        }
      }

      typePlaceholder();
    }
  }, []);

  return (
    <div>
      <form className="pt-6" onSubmit={handleSubmit}>
        <div className="flex overflow-auto lg:gap-[2px]">
          <input
            type="radio"
            id="rent-properties"
            name="search_type"
            value="rent_properties"
            onChange={() => setSelectedQuery("rent_properties")}
            className="peer/rent-properties hidden"
            checked={selectedQuery === "rent_properties"}
          />
          <label
            htmlFor="rent-properties"
            className="lg:text-md flex-shrink-0 cursor-pointer rounded-t-lg bg-black bg-opacity-45 px-2 py-2 text-sm font-semibold text-white peer-checked/rent-properties:bg-s2-teal peer-checked/rent-properties:text-white lg:px-4"
          >
            Arrendar vivienda
          </label>
          <input
            type="radio"
            id="rent-properties-commercial"
            name="search_type"
            value="rent_properties_commercial"
            onChange={() => setSelectedQuery("rent_properties_commercial")}
            className="peer/rent-properties-commercial hidden"
            checked={selectedQuery === "rent_properties_commercial"}
          />
          <label
            htmlFor="rent-properties-commercial"
            className="lg:text-md flex-shrink-0 cursor-pointer rounded-t-lg bg-black bg-opacity-45 px-2 py-2 text-sm font-semibold text-white peer-checked/rent-properties-commercial:bg-s2-teal peer-checked/rent-properties-commercial:text-white lg:px-4"
          >
            Arrendar comercio
          </label>
          <input
            type="radio"
            id="buy-properties"
            name="search_type"
            value="buy_properties"
            onChange={() => setSelectedQuery("buy_properties")}
            className="peer/buy-properties hidden"
            checked={selectedQuery === "buy_properties"}
          />
          <label
            htmlFor="buy-properties"
            className="lg:text-md flex-shrink-0 cursor-pointer rounded-t-lg bg-black bg-opacity-45 px-2 py-2 text-sm font-semibold text-white peer-checked/buy-properties:bg-s2-teal peer-checked/buy-properties:text-white lg:px-4"
          >
            Comprar usado
          </label>
        </div>
        <div className="s2-banner__form !py-0">
          <div style={{ flex: 1 }} className={cn(!vissible && "hidden")}>
            <input
              placeholder="Busca por nombre del departamento, ciudad, barrio o palabras claves"
              className="h-[65px] w-full border p-4 placeholder:text-[15px]"
              onChange={(e) => setCustomSearchText(e.target.value)}
              value={customSearchText}
              id="dynamic-placeholder"
            />
          </div>

          <div style={{ flex: 1.15 }} className={cn(vissible && "hidden")}>
            <CreatableSelect
              isClearable
              className="s2-banner__select-second"
              classNamePrefix="s2-select"
              createOptionPosition="first"
              value={locationValue}
              options={locations}
              placeholder="Busca por nombre del departamento, ciudad, barrio o palabras claves"
              variant="search"
              isLoading={loading}
              isDisabled={loading || isButtonDisabled}
              formatCreateLabel={(text) => `Buscar '${text}'`}
              onChange={(selected) => {
                setLocationValue(selected);
              }}
            />
          </div>

          <div style={{ flex: 0.45 }} className={cn(vissible && "hidden")}>
            <Select
              isClearable
              className="s2-banner__select-last"
              classNamePrefix="s2-select"
              value={typeValue}
              placeholder="Tipo de inmueble"
              options={types}
              isLoading={loading}
              isDisabled={loading || isButtonDisabled}
              variant="large"
              onChange={(selected) => {
                setTypeValue(selected);
              }}
            />
          </div>

          <Button className="s2-banner__btn-mobile" size="fluid">
            Buscar inmueble
          </Button>
          <button className="s2-banner__btn-desktop" type="submit">
            <LoupeIcon />
          </button>
        </div>

        <div className="text-center lg:text-left">
          <Button
            tint="gray"
            shape="square"
            onClick={toggleSearch}
            type="button"
            className="my-4 lg:my-0"
          >
            {!vissible
              ? "Buscar por palabras claves aquí"
              : "Buscar por tipo de inmueble"}
          </Button>
        </div>
      </form>
      <SearchModal />
    </div>
  );
}

export default SearchTabsB;
