import React from "react";
import HeroBanner from "@coninsa-s2/banner";
import Container from "@coninsa-s2/container";
import Heading from "@coninsa-s2/heading";

import SearchBarA from "../search-bar-a";
import SearchBarB from "../search-bar-b";

import bannerImage from "../../../images/property-banner.jpg";
import bannerMobileImage from "../../../images/property-banner-mobile.jpg";

function SearchTabs() {
  const [randmonIndex, setRandmonIndex] = React.useState(0);

  React.useEffect(() => {
    setRandmonIndex(Math.floor(Math.random() * 2));
  }, []);

  return (
    <HeroBanner imageUrl={bannerImage} imageUrlMobile={bannerMobileImage}>
      <Container>
        <Heading tint="white" size="4xl" className="s2-banner__label">
          ¡Descubre tu inmueble ideal <br /> de vivienda o comercio!
        </Heading>

        {randmonIndex === 0 && <SearchBarA />}
        {randmonIndex === 1 && <SearchBarB />}
      </Container>
    </HeroBanner>
  );
}

export default SearchTabs;
